import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/Insta.png'
import _imports_1 from '../assets/imgs/63e5079c2efbf624bd00d1e3_hero verge-p-800.png'
import _imports_2 from '../assets/imgs/63ebce23e53ac60a7fa7bd43_hero youtube.png'
import _imports_3 from '../assets/imgs/logo.png'
import _imports_4 from '../assets/imgs/paint.png'
import _imports_5 from '../assets/imgs/tweets.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-items-center justify-content-center" }
const _hoisted_3 = { class: "col-md-5 bioohub" }
const _hoisted_4 = { class: "d-flex justify-content-center" }
const _hoisted_5 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-md-4 mt-5 d-none d-md-block" }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "animate__animated animate__zoomIn img-fluid img1"
        }),
        _createElementVNode("img", {
          src: _imports_1,
          class: "animate__animated animate__zoomIn img-fluid img3"
        }),
        _createElementVNode("img", {
          src: _imports_2,
          class: "animate__animated animate__zoomIn img-fluid img2"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_3,
          class: "animate__animated animate__bounceIn img-fluid logo"
        }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "animate__animated animate__bounceIn h3-bioohub" }, "BiooHub", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "animate__animated animate__fadeInUp link-in-bio" }, [
          _createTextVNode("Um simples link na bio "),
          _createElementVNode("br"),
          _createTextVNode(" Agora é uma poderosa página de apresentação. ")
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "animate__animated animate__fadeInUp p" }, " Conecte todas as suas redes, conteúdos e projetos em um só lugar! ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/cadastro",
            class: "animate__animated animate__fadeIn btn btn-bioohub btn-lg"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Cadastre-se no BiooHub ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/login",
            class: "animate__animated animate__fadeIn botao-login"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Log-in")
            ])),
            _: 1
          })
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-md-3 d-none d-md-block" }, [
        _createElementVNode("img", {
          src: _imports_4,
          class: "animate__animated animate__zoomIn img-fluid img4 img-fluid"
        }),
        _createElementVNode("img", {
          src: _imports_5,
          class: "animate__animated animate__zoomIn img-fluid img5 img-fluid"
        })
      ], -1))
    ])
  ]))
}