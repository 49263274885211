import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/63e5079c2efbf624bd00d1e3_hero verge-p-800.png'
import _imports_1 from '../assets/imgs/63ebce23e53ac60a7fa7bd43_hero youtube.png'
import _imports_2 from '../assets/imgs/Insta.png'


const _hoisted_1 = { class: "container d-flex justify-content-center align-items-center vh-100" }
const _hoisted_2 = { class: "row w-75" }
const _hoisted_3 = { class: "col-md-6 login-form" }
const _hoisted_4 = { class: "col-md-12 d-flex justify-content-center mt-2" }
const _hoisted_5 = { class: "animate__animated animate__zoomIn mb-4" }
const _hoisted_6 = { class: "animate__animated animate__zoomIn input-group mb-4" }
const _hoisted_7 = ["type"]
const _hoisted_8 = { class: "animate__animated animate__zoomIn d-grid gap-2" }
const _hoisted_9 = { class: "animate__animated animate__zoomIn d-flex justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerta = _resolveComponent("Alerta")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "animate__animated animate__zoomIn mb-5" }, "Faça log-in com sua conta", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Alerta, { mensagem_alerta: _ctx.mensagem_alerta }, null, 8, ["mensagem_alerta"])
        ]),
        _createElementVNode("form", null, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              class: "form-control",
              placeholder: "Email",
              "aria-label": "Email"
            }, null, 512), [
              [_vModelText, _ctx.email]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: _ctx.mostrar_senha ? 'text' : 'password',
              class: "form-control",
              placeholder: "Senha",
              "aria-label": "Senha",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.senha) = $event))
            }, null, 8, _hoisted_7), [
              [_vModelDynamic, _ctx.senha]
            ]),
            _createElementVNode("button", {
              class: "btn btn-outline-secondary",
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.alternarExibicaoSenha && _ctx.alternarExibicaoSenha(...args)))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.mostrar_senha ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye')
              }, null, 2)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.fazerLogin && _ctx.fazerLogin(...args))),
              class: "btn btn-secondary"
            }, "Log In")
          ]),
          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"animate__animated animate__zoomIn mt-3 mb-3 text-center\" data-v-9f17486c><h6 class=\"animate__animated animate__zoomIn\" data-v-9f17486c>Ou</h6></div><div class=\"animate__animated animate__zoomIn d-grid gap-2 mt-2\" data-v-9f17486c><button type=\"button\" class=\"animate__animated animate__zoomIn btn btn-primary\" data-v-9f17486c><i class=\"fa-brands fa-google\" data-v-9f17486c></i> Log in com Google </button></div>", 2)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: "/cadastro",
              class: "animate__animated animate__zoomIn botao-cadastro-tela-login"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Ou cadastre-se aqui")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "img-rodando animate__animated animate__zoomIn col-md-6 d-none d-md-block" }, [
        _createElementVNode("img", {
          class: "img-fluid img-rodando-1",
          style: {"width":"80%"},
          src: _imports_0
        }),
        _createElementVNode("img", {
          class: "img-fluid img-rodando-2",
          src: _imports_1
        }),
        _createElementVNode("img", {
          class: "img-fluid img-rodando-3",
          src: _imports_2
        })
      ], -1))
    ])
  ]))
}