import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/11a570b9-08cb-461b-bef6-5b83e8a7f991.jpeg'


const _hoisted_1 = { class: "container pagina-usuario" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "animate__animated animate__zoomIn d-flex justify-content-center mt-5 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"col-md-6 mt-5 d-flex flex-column align-items-center text-center d-md-block\"><div class=\"animate__animated animate__zoomIn avatar-circle d-flex flex-column justify-content-center align-items-center\"><img src=\"" + _imports_0 + "\" alt=\"Avatar\" class=\"img-fluid rounded-circle\" style=\"width:150px;height:150px;object-fit:cover;\"></div><div class=\"mt-4 animate__animated animate__zoomIn w-100\"><h2 class=\"text-start text-md-start text-center\">Mateus Maranhao</h2></div><div class=\"mt-2 animate__animated animate__zoomIn w-100\"><p class=\"text-start text-md-start text-center\" style=\"color:rgb(190, 190, 190);\">lorem ipsum dolor sit amet</p></div></div><div class=\"col-md-6 mt-5\"><div class=\"d-flex flex-wrap gap-3 justify-content-between\"><div class=\"animate__animated animate__zoomIn card link-card card-small d-flex flex-column align-items-center justify-content-center position-relative\"><i class=\"fa-solid fa-mountain fa-2x\"></i></div><div class=\"animate__animated animate__zoomIn card link-card card-vertical d-flex flex-column align-items-center justify-content-center\"><i class=\"fa-solid fa-globe fa-2x\"></i></div><div class=\"animate__animated animate__zoomIn card link-card card-horizontal d-flex flex-column align-items-center justify-content-center\"><i class=\"fa-brands fa-spotify fa-2x\"></i></div><div class=\"animate__animated animate__zoomIn card link-card card-large-square d-flex flex-column align-items-center justify-content-center\"><i class=\"fa-solid fa-video fa-2x\"></i></div><div class=\"animate__animated animate__zoomIn card link-card card-vertical2 d-flex flex-column align-items-center justify-content-center\"><i class=\"fa-solid fa-location-dot fa-2x\"></i></div><div class=\"animate__animated animate__zoomIn card link-card card-small2 d-flex flex-column align-items-center justify-content-center\"><i class=\"fa-solid fa-share-alt fa-2x\"></i></div></div></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/cadastro",
          class: "btn btn-bioohub"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Criar meu Bioohub ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}