import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "animate__animated animate__flipInX",
    "leave-active-class": "animate__animated animate__flipOutX"
  }, {
    default: _withCtx(() => [
      (_ctx.mensagem_alerta)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["mt-3 mb-3 text-center alert", _ctx.mensagem_alerta.status])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.mensagem_alerta.icone)
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(_ctx.mensagem_alerta.mensagem), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}