import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/imgs/63ebce23e53ac60a7fa7bd43_hero youtube.png'
import _imports_1 from '../assets/imgs/Insta.png'


const _hoisted_1 = { class: "container d-flex justify-content-center align-items-center vh-100" }
const _hoisted_2 = { class: "row w-75" }
const _hoisted_3 = { class: "col-md-12 d-flex justify-content-center mt-2" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-6" }
const _hoisted_6 = { class: "animate__animated animate__zoomIn input-name input-group mb-3" }
const _hoisted_7 = { class: "d-flex justify-content-start" }
const _hoisted_8 = { class: "d-none d-md-block col-md-6" }
const _hoisted_9 = { class: "col-md-6 mb-2" }
const _hoisted_10 = { class: "d-flex justify-content-start" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-6 mb-2" }
const _hoisted_13 = { class: "col-md-6" }
const _hoisted_14 = { class: "input-group" }
const _hoisted_15 = ["type"]
const _hoisted_16 = { class: "d-flex mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerta = _resolveComponent("Alerta")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GrabLink = _resolveComponent("GrabLink")!
  const _component_Planos = _resolveComponent("Planos")!
  const _component_RedesSociais = _resolveComponent("RedesSociais")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Alerta, { mensagem_alerta: _ctx.mensagem_alerta }, null, 8, ["mensagem_alerta"])
      ]),
      (_ctx.etapa === 1)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([{ 'animate__animated animate__fadeOutLeft': _ctx.animacaoSaida, 'animate__animated animate__fadeInRight': _ctx.animacaoEntrada }, "cadastro"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "animate__animated animate__zoomIn h2-cadastro mb-3" }, "Primeiro, escolha seu link exclusivo ", -1)),
                _cache[16] || (_cache[16] = _createElementVNode("h5", { class: "animate__animated animate__zoomIn h5-cadastro" }, "Os bons ainda estão disponíveis! ", -1)),
                _createElementVNode("form", {
                  onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.proximaEtapa && _ctx.proximaEtapa(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "input-group-text" }, "bioohub.me/", -1)),
                    _withDirectives(_createElementVNode("input", {
                      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validarUsuario && _ctx.validarUsuario(...args))),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.usuarios.usuario) = $event)),
                      type: "text",
                      class: "form-control",
                      placeholder: "usuario",
                      "aria-label": "Username"
                    }, null, 544), [
                      [_vModelText, _ctx.usuarios.usuario]
                    ])
                  ]),
                  _createElementVNode("span", {
                    style: _normalizeStyle({ color: _ctx.corMensagemUsuario })
                  }, _toDisplayString(_ctx.mensagemUsuario), 5),
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "d-grid gap-2" }, [
                    _createElementVNode("button", { class: "mt-2 animate__animated animate__zoomIn btn btn-secondary btn-form" }, "Próxima etapa")
                  ], -1))
                ], 32),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_router_link, {
                    to: "/login",
                    class: "animate__animated animate__zoomIn botao-login-tela-cadastro"
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" Ou faça log-in ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_GrabLink)
              ])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.etapa === 2)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["row cadastro", { 'animate__animated animate__fadeOutLeft': _ctx.animacaoSaida, 'animate__animated animate__fadeInRight': _ctx.animacaoEntrada }])
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  class: "arrow-left",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.etapaAnterior()))
                }, _cache[17] || (_cache[17] = [
                  _createElementVNode("i", { class: "fa-solid fa-arrow-left" }, null, -1)
                ]))
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("h2", { class: "mb-5" }, "Insira seu e-mail e senha", -1)),
              _createElementVNode("form", {
                onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.proximaEtapa && _ctx.proximaEtapa(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.usuarios.email) = $event)),
                      type: "text",
                      class: "form-control",
                      placeholder: "e-mail",
                      "aria-label": "E-mail"
                    }, null, 512), [
                      [_vModelText, _ctx.usuarios.email]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _withDirectives(_createElementVNode("input", {
                        onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validarSenha && _ctx.validarSenha(...args))),
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.usuarios.senha) = $event)),
                        type: _ctx.mostrar_senha ? 'text' : 'password',
                        class: "form-control",
                        placeholder: "senha",
                        "aria-label": "Senha"
                      }, null, 40, _hoisted_15), [
                        [_vModelDynamic, _ctx.usuarios.senha]
                      ]),
                      _createElementVNode("button", {
                        class: "btn btn-outline-secondary",
                        type: "button",
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.alternarExibicaoSenha && _ctx.alternarExibicaoSenha(...args)))
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(_ctx.mostrar_senha ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye')
                        }, null, 2)
                      ])
                    ]),
                    _createElementVNode("span", {
                      style: _normalizeStyle({ color: _ctx.corMensagemSenha })
                    }, _toDisplayString(_ctx.mensagemSenha), 5)
                  ])
                ]),
                _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"d-grid gap-2 mt-3\"><button class=\"btn btn-secondary\">Criar conta</button></div><div class=\"mt-3 mb-3\"><h6>Ou</h6></div><div class=\"d-grid gap-2 mt-2 mb-2\"><button class=\"btn btn-primary\"><i class=\"fa-brands fa-google\"></i> Cadastre-se com o Google </button></div>", 3))
              ], 32)
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-md-6 d-none d-md-block img-rodando animate__animated animate__zoomIn" }, [
              _createElementVNode("img", {
                class: "img-fluid img-rodando-2",
                src: _imports_0
              }),
              _createElementVNode("img", {
                class: "img-fluid img-rodando-3",
                src: _imports_1
              })
            ], -1))
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.etapa === 3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass([{ 'animate__animated animate__fadeOutLeft': _ctx.animacaoSaida, 'animate__animated animate__fadeInRight': _ctx.animacaoEntrada }, "cadastro col-md-12 mt-3 mb-3"])
          }, [
            _createVNode(_component_Planos),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("button", {
                class: "btn btn-light flex-grow-1 me-1",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.etapaAnterior()))
              }, "Voltar"),
              _createElementVNode("button", {
                class: "btn btn-dark flex-grow-1",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.proximaEtapa()))
              }, "Próxima")
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.etapa === 4)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass([{ 'animate__animated animate__fadeOutLeft': _ctx.animacaoSaida, 'animate__animated animate__fadeInRight': _ctx.animacaoEntrada }, "cadastro col-md-12"])
          }, [
            _createVNode(_component_RedesSociais, {
              onCadastrar: _cache[11] || (_cache[11] = ($event: any) => (_ctx.cadastrarUsuario()))
            })
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}