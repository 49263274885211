<template>
    <div class="modal fade" id="alterarUsuarioModal" tabindex="-1" aria-labelledby="alterarUsuarioModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="alterarUsuarioModalLabel">Alterar Nome de Usuário</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="novoUsuario" class="form-label">Novo Nome de Usuário</label>
                            <input type="text" class="form-control" id="novoUsuario"
                                placeholder="Digite seu novo nome de usuário">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary">Salvar alterações</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class AlterarUsuario extends Vue { }
</script>