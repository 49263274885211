import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container redes-sociais" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 icones" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_7 = { class: "d-flex mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Agora, vamos adicionar suas contas de mídia social à sua página.", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.redesSociais, (rede, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "d-flex align-items-center mb-3"
          }, [
            _createElementVNode("img", {
              src: rede.icon,
              class: "img-fluid me-3",
              alt: `${rede.nome} Icon`,
              style: {"width":"40px"}
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "input-group-text" }, "@", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": ($event: any) => ((rede.usuario) = $event),
                type: "text",
                class: "form-control",
                placeholder: rede.placeholder,
                "aria-label": "Username"
              }, null, 8, _hoisted_6), [
                [_vModelText, rede.usuario]
              ])
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cadastrar'))),
            class: "btn btn-dark flex-grow-1 me-1"
          }, "Próximo"),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cadastrar'))),
            class: "btn btn-light flex-grow-1"
          }, "Pular")
        ])
      ]),
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"col-md-6\"><h2>Sua página</h2><div class=\"row\"><div class=\"d-flex justify-content-center\"><div class=\"col-md-4 mb-3 me-3\"><div class=\"card\"><div class=\"card-body\"><i class=\"fa-brands fa-instagram\"></i><h6>@mateusmaranhao12</h6><button class=\"btn btn-primary\">Seguir</button></div></div></div><div class=\"col-md-4 mb-3\"><div class=\"card\"><div class=\"card-body\"><i class=\"fa-brands fa-github\"></i><h6>Mateus Maranhão</h6><button class=\"btn btn-dark\">Seguir</button></div></div></div></div></div></div>", 1))
    ])
  ]))
}