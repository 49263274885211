import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/imgs/grablink.png'


const _hoisted_1 = { class: "animate__animated animate__zoomIn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      class: "img-fluid",
      src: _imports_0
    }, null, -1)
  ])))
}