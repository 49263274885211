<template>
    <div class="animate__animated animate__zoomIn">
        <img class="img-fluid" src="../assets/imgs/grablink.png">
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class GrabLink extends Vue { }
</script>